import { SettingsValueProps } from './components/settings/type';
import axios, { AxiosInstance } from 'axios'

export const APP_NAME = 'Impare Educação'
export const LOGO_PATH = '/images/app_logo.png'
export const LOGIN_IMAGE_PATH = '/images/login_image.jpeg'
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const INFANTIL_ID = '633ed422698499907d3969cf';
export const FUNDAMENTAL_INICIAIS_ID = '633ed42d698499907d3969d6';
export const FUNDAMENTAL_FINAIS_ID = '633ed436698499907d3969dd';
export const INFANTIL_NAME = 'INFANTIL';
export const FUNDAMENTAL_INICIAIS_NAME = 'FUNDAMENTAL - ANOS INICIAIS';
export const FUNDAMENTAL_FINAIS_NAME = 'FUNDAMENTAL - ANOS FINAIS';

//export const HOST_API = 'https://api.guarulhos.impare.com.br/';
export const HOST_API = 'https://educacao.api.impare.com.br/';
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const api: AxiosInstance = axios.create({
  baseURL: HOST_API
});

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'blue',
  themeLayout: 'horizontal',
  themeStretch: false,
};
